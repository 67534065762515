@tailwind base;
/* add following lines */
@layer base {
  img {
    @apply inline-block;
  }
}

@import "components.css";
@import "phone-input.css";
@tailwind utilities;

* {
  box-sizing: border-box;
}

.font-inherit {
  font-size: inherit;
}